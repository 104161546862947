const currency = 'RUB'

/**
 * Class EcommerceApiService
 *
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
 */
export default class EcommerceApiService {
  public static addToCart (productId: string, quantity = 1): void {
    this.triggerEvent('add_to_cart', { items: [{ item_id: productId, quantity }] })
  }

  public static beginCheckout (items: object[], total: number, promoCodeName: string | null): void {
    const data: any = { currency, items, value: total }

    if (promoCodeName !== null) {
      data.coupon = promoCodeName
    }

    this.triggerEvent('begin_checkout', data)
  }

  public static purchase (
    items: object[],
    orderId: string,
    total: number,
    shippingCost: number,
    promoCodeName: string | null
  ): void {
    const data: any = { currency, items, shipping: shippingCost, transaction_id: orderId, value: total }

    if (promoCodeName !== null) {
      data.coupon = promoCodeName
    }

    this.triggerEvent('purchase', data)
  }

  public static removeFromCart (productId: string, quantity = 1): void {
    this.triggerEvent('remove_from_cart', { items: [{ item_id: productId, quantity }] })
  }

  public static viewCart (items: object[], total: number): void {
    this.triggerEvent('view_cart', { currency, items, value: total })
  }

  public static viewItem (
    productId: string,
    productName: string,
    price: number | null,
    brandName: string | null,
    categoryNames: string[]
  ): void {
    const data: any = {}
    const itemData: any = { item_id: productId, item_name: productName, item_category: categoryNames }

    if (price !== null) {
      data.currency = currency
      data.value = price
      itemData.price = price
    }

    if (brandName !== null) {
      itemData.item_brand = brandName
    }

    data.items = [itemData]

    this.triggerEvent('view_item', data)
  }

  private static triggerEvent (name: string, data: object): void {
    // console.log('triggerEvent', name, data)

    if (Object.prototype.hasOwnProperty.call(window, 'dataLayer')) {
      try {
        // @ts-ignore
        window.dataLayer.push({ event: name, ecommerce: data })
      } catch (e) {
        console.error(e)
      }
    }

    // if (Object.prototype.hasOwnProperty.call(window, 'gtag')) {
    //   try {
    //     // @ts-ignore
    //     window.gtag('event', name, data)
    //   } catch (e) {
    //     console.error(e)
    //   }
    // }
  }
}

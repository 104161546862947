import constants from '~/constants'
import EcommerceApiService from '~/services/ecommerce-api-service'
import MindboxApiService from '~/services/mindbox-api-service'
import RetailRocketApiService from '~/services/retail-rocket-api-service'
import { useAuthStore } from '~/store/auth'
import { useProductsStore } from '~/store/products'
import type { ProductData } from '~/types/products'

const messages = {
  // Cart
  failedToAddProductToCart: 'Не удалось добавить товар в корзину.',
  failedToUpdateCartProductQuantity: 'Не удалось изменить количество товара в корзине.',
  productIsAddedToCart: 'Товар добавлен в корзину.',

  // Wish list
  failedToAddProductToWishList: 'Не удалось добавить товар в «Избранное».',
  failedToDeleteProductFromWishList: 'Не удалось удалить товар из «Избранного».',
  productIsAddedToWishList: 'Товар добавлен в «Избранное».',
  productIsDeletedFromWishList: 'Товар удалён из «Избранного».'
}

export function useProduct () {
  const { $apiHelper, $toast } = useNuxtApp()
  const authStore = useAuthStore()
  const productsStore = useProductsStore()

  async function addProductToCart (productId: number | string, quantity = 1, isRecommendation = false): Promise<void> {
    if (typeof productId === 'number') {
      productId = String(productId)
    }

    const { failedToAddProductToCart, productIsAddedToCart } = messages

    productsStore.incrementCartTotalItems(quantity)

    try {
      if (!authStore.isUser) {
        await createGuestIfNeeded()
      }

      const data = await $apiHelper.cartProducts.createCartProduct(productId, quantity) as any

      productsStore.setCartProductQuantity({ id: productId, quantity })

      $toast.success(productIsAddedToCart)

      EcommerceApiService.addToCart(productId, quantity)

      if (!isRecommendation) {
        RetailRocketApiService.addToBasket(productId)
      }

      if (data?.meta?.cartProducts) {
        MindboxApiService.setCart(
          data.meta.cartProducts.map(
            ({ id, price, quantity }: any): ProductData => ({
              id,
              type: 'products',
              attributes: { price },
              meta: { quantity }
            })
          )
        )
      }
    } catch (error) {
      console.error(error)

      productsStore.decrementCartTotalItems(quantity)

      $toast.error(failedToAddProductToCart)
    }
  }

  async function createGuestIfNeeded (): Promise<void> {
    const guestIdCookie = useCookie('guestId', constants.cookieOptions)

    if (!guestIdCookie.value) {
      const { data: { id: guestId } } = await $apiHelper.guests.createGuest() as any

      guestIdCookie.value = guestId

      authStore.setGuestId(guestId)
    }
  }

  async function setCartProductQuantity (productId: string, quantity = 1): Promise<void> {
    const currentQuantity = productsStore.getCartProductQuantity(productId)

    if (currentQuantity === null) {
      await addProductToCart(productId)

      return
    }

    const deltaQuantity = quantity - currentQuantity

    if (deltaQuantity === 0) {
      return
    }

    if (deltaQuantity > 0) {
      productsStore.incrementCartTotalItems(deltaQuantity)
    } else {
      productsStore.decrementCartTotalItems(-deltaQuantity)
    }

    try {
      let data: any

      if (quantity) {
        data = await $apiHelper.cartProducts.updateCartProduct(productId, quantity)

        productsStore.setCartProductQuantity({ id: productId, quantity })
      } else {
        data = await $apiHelper.cartProducts.deleteCartProduct(productId)

        productsStore.removeCartProduct(productId)
      }

      if (data?.meta?.cartProducts) {
        MindboxApiService.setCart(
          data.meta.cartProducts.map(
            ({ id, price, quantity }: any): ProductData => ({
              id,
              type: 'products',
              attributes: { price },
              meta: { quantity }
            })
          )
        )
      }
    } catch (error) {
      console.error(error)

      if (deltaQuantity > 0) {
        productsStore.decrementCartTotalItems(deltaQuantity)
      } else {
        productsStore.incrementCartTotalItems(-deltaQuantity)
      }

      $toast.error(messages.failedToUpdateCartProductQuantity)
    }
  }

  async function toggleIsInWishList (productData: ProductData): Promise<void> {
    const {
      failedToAddProductToWishList,
      failedToDeleteProductFromWishList,
      productIsAddedToWishList,
      productIsDeletedFromWishList
    } = messages
    const { id, meta } = productData
    const { isInWishList } = meta

    meta.isInWishList = !isInWishList

    if (isInWishList) {
      productsStore.decrementWishListTotalItems()
    } else {
      productsStore.incrementWishListTotalItems()
    }

    try {
      if (!authStore.isUser) {
        await createGuestIfNeeded()
      }

      let data: any

      if (isInWishList) {
        data = await $apiHelper.wishListProducts.deleteWishListProduct(id)
      } else {
        data = await $apiHelper.wishListProducts.createWishListProduct(id)
      }

      $toast.success(isInWishList ? productIsDeletedFromWishList : productIsAddedToWishList)

      if (data?.meta?.wishListProducts) {
        MindboxApiService.setWishList(
          data.meta.wishListProducts.map(
            ({ id, price }: any): ProductData => ({
              id,
              type: 'products',
              attributes: { price }
            })
          )
        )
      }
    } catch (error) {
      console.error(error)

      meta.isInWishList = isInWishList

      if (isInWishList) {
        productsStore.incrementWishListTotalItems()
      } else {
        productsStore.decrementWishListTotalItems()
      }

      $toast.error(isInWishList ? failedToDeleteProductFromWishList : failedToAddProductToWishList)
    }
  }

  return { addProductToCart, createGuestIfNeeded, setCartProductQuantity, toggleIsInWishList }
}
